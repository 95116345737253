export enum ColorScheme {
  PRIMARY_VAR = 'var(--primary)',
  SUCCESS_VAR = 'var(--success)',
  DANGER_VAR = 'var(--danger)',
  ACCENT_1_VAR = 'var(--accent-1)',
  ACCENT_2_VAR = 'var(--accent-2)',
  ACCENT_3_VAR = 'var(--accent-3)',
  ACCENT_HIGHLIGHT_VAR = 'var(--accent-highlight)',

  GRAY_DARK_STRONG_VAR = 'var(--gray-dark-strong)',
  GRAY_DARK_VAR = 'var(--gray-dark)',
  GRAY_MEDIUM_VAR = 'var(--gray-medium)',
  GRAY_LIGHT_STRONG_VAR = 'var(--gray-light-strong)',
  GRAY_LIGHT_VAR = 'var(--gray-light)',

  BACKGROUND_DASHBOARD_VAR = 'var(--background-dashboard)',
}
