export enum ROUTES_MAIN {
  DASHBOARD = 'dashboard',
  LOGIN = 'login',
  TRADING = 'trading',
  GROUPS = 'groups',
  WATCHLIST = 'watchlist',
  STOCK_DETAILS = 'stock-details',
  STOCK_SCREENER = 'stock-screener',
  MARKET = 'market',
  MARKET_CALENDAR = 'calendar',
  TOP_PERFORMERS = 'top-performers',
  CRYPTO = 'crypto',
  NEWS = 'news',
  ECONOMICS = 'economics',
  HALL_OF_FAME = 'hall-of-fame',
  COMPARE_USERS = 'compare-users',
  NOT_FOUND = 'not-found',
}

export enum ROUTES_MARKET {
  OVERVIEW = 'overview',
  CUSTOM = 'custom',
}

export enum ROUTES_STOCK_DETAILS {
  OVERVIEW = 'overview',
  FINANCIALS = 'financials',
  HOLDERS = 'holders',
  RATIOS = 'ratios',
  TRADES = 'trades',
  NEWS = 'news',
}

export enum ROUTES_GROUP {
  DETAILS = 'details',
}
